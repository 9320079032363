// Tagalog
export default{
    form: {
        startSurveyButton: "Magsimula",
        nextButton: "Susunod",
        submitAnswerButton: "Ipasa ang Sagot",
        continueButton: "Magpatuloy",
        finishButton: "Tapos",
        headerPercentageCompletion: 'kumpleto',
        headerQuestionsCompleted: 'mga tanong',
        headerDefaultErrorMessage: 'Naku. May nangyaring mali.',
        brandingPoweredBy: 'Pinapatakbo ng',
        brandingPromoWhySurvey: 'Bakit magsarbey kung pwede kang gumamit ng <b>{product}</b>?',
        brandingPromoGetStarted: 'Simulan na ngayon!',
        submissionPending: "Ipinapasa ang iyong voiceform. Huwag isara ang iyong browser window.",
        submissionCanCloseWindow: "Matagumpay na naipasa ang iyong voiceform. Maaari mo nang isara ang window 👍",
        mediaAttachmentOverlayCloseButton: "Isara",
        expiredFormHeader: "Salamat sa pagbisita!",
        expiredFormMessage: "Mukhang nag-expire na ang sarbey na ito. Mangyaring kontakin ang tagagawa ng voiceform upang ipaalam sa kanila.",
        notPublishedFormHeader: "Hindi pa nailathala ang voiceform!",
        notPublishedFormMessage: "Mukhang hindi pa nailalathala ang sarbey na ito. Mangyaring kontakin ang tagagawa ng voiceform upang ipaalam sa kanila.",
        notPublishedFormRetryButton: "Subukang Muli",
        errorFormHeader: "Naku!",
        errorFormMessage: "Maaaring may nangyaring mali. Subukang muli mamaya.",
        errorFormRetryButton: "Subukang Muli",
        emptyFormHeader: "Salamat sa pagbisita!",
        emptyFormMessage: "Naku! Mukhang walang laman ang form na ito sa kasalukuyan. Paumanhin sa abala. Mangyaring kontakin ang tagagawa ng form upang ayusin ito. Salamat sa iyong pag-unawa at pakikipagtulungan!",

        restoreFormHeader: "Mukhang may nakabinbing form ka!",
        restoreFormMessage: "Nais mo bang ipagpatuloy kung saan ka huminto?",
        restoreFormButton: "Ipagpatuloy ang Form",
        restoreFormButtonNew: "Magsimula Muli",

        audioPermissionHeader: "Nais mo bang sumagot gamit ang boses?",
        audioPermissionMessage: "Ang pagsasalita ay magpapadali at magpapabilis ng pagsagot.",
        audioPermissionAcceptButton: "Oo, gusto ko",
        audioPermissionDenyButton: "Hindi, salamat",
        audioPermissionErrorHeader: "Hindi magagamit ang mikropono",
        audioPermissionErrorMessage: "Suriin ang mga pahintulot ng iyong browser upang bigyan ng access ang mikropono.",
        audioPermissionErrorButton: "Sige",
        audioRecorderFailedUpload: "Hindi ma-upload ang recording",
        silenceDetectorError: "Hindi ka namin marinig. Suriin ang iyong mga setting ng mikropono. Tip: maaaring ang iyong external mic ang dahilan.",
        audioRecorderButtonRecord: "Mag-record",
        audioRecorderButtonRecordMore: "Mag-record pa",
        voiceResponseSelectionMessage: "Piliin kung paano ka sasagot...",
        voiceResponseSelectionOrDivider: "o",
        voiceResponseTextInputPlaceholder: "I-type ang sagot",
        voiceResponseTextInputCharacterCounterMinMessage: "Magpasok ng hindi bababa sa {min} na karakter",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} na karakter (minimum {min} na karakter)",
        voiceResponseTextInputCharacterValidationMessage: "{count} na karakter (minimum {min} na karakter)",

        // checkbox
        checkboxValidationTooFew: "Kailangan mong pumili ng hindi bababa sa {min} na opsyon",
        checkboxValidationTooMany: "Kailangan mong pumili ng hindi hihigit sa {max} na opsyon",
        checkboxNoneOfTheAboveOption: "Wala sa mga nabanggit",
        checkboxInvalidAnswer: "Hindi tamang sagot.",

        // datepicker
        datePickerPlaceholder: "Piliin ang petsa",

        // dropdown
        // email
        emailLabel: "Email",
        emailInvalid: "Hindi wastong email.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Mag-record ng video",
        fileUploadPluginScreenCast: "Screen Cast",
        fileUploadVideoImportFilesDefault: 'Mag-record o mag-upload ng video gamit ang:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Pumili ng opsyon sa pag-record ng video:',
        fileUploadVideoImportFilesNoCamera: 'Pindutin ang isang button upang mag-screen record ng video',
        fileUploadVideoImportFilesNoScreenCast: 'Pindutin ang isang button upang mag-record ng video',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Walang available na opsyon para mag-record ng video',

        // matrix
        matrixValidationMessage: "Wasto ang sagot.",
        matrixRow: "Hilera",
        matrixColumn: "Kolum",
        matrixRowRequired: "Kinakailangan ang hilera {rowTitle}.",
        matrixRadioGroup: "Radio group",
        matrixCheckboxGroup: "Checkbox group",
        matrixGroupRequired: "Para sa hilera {rowTitle}, kinakailangan ang {type} {groupTitle}.",
        matrixColumnRequired: "Para sa hilera {rowTitle}, kinakailangan ang kolum {columnTitle}.",
        matrixColumnInvalid: "Para sa hilera {rowTitle}, hindi wasto ang kolum {columnTitle}.",
        matrixRequired: "Kinakailangan.",
        matrixNumericMustBeNumber: "Dapat isang numero.",
        matrixNumericMustBeGreaterThenMin: "Dapat higit sa {min}.",
        matrixNumericMustBeLessThenMax: "Dapat mas mababa sa {max}.",
        matrixNumericMustBeInteger: "Dapat isang buumbilang.",
        matrixNumericInvalidNumber: "Hindi wastong numero.",

        // name
        nameLabel: "Pangalan",
        nameInvalid: "Ibigay ang pangalan at apelyido.",

        // nps
        npsNotLikely: "Hindi malamang",
        npsExtremelyLikely: "Lubos na Malamang",

        // numeric input
        numericInputRequired: "Kinakailangan.",
        numericInputMustBeNumber: "Dapat isang numero.",
        numericInputMustBeGreaterThenMin: "Dapat higit sa {min}.",
        numericInputMustBeLessThenMax: "Dapat mas mababa sa {max}.",
        numericInputMustBeInteger: "Dapat isang buumbilang.",
        numericInputInvalidNumber: "Hindi wastong numero.",
        numericInputPlaceholder: "I-type ang numero",

        // phone
        phoneInvalid: "Hindi wastong numero ng telepono.",
        phoneCountrySelectorLabel: 'Code ng bansa',
        phoneCountrySelectorError: 'Piliin ang bansa',
        phoneNumberLabel: 'Numero ng telepono',
        phoneExample: 'Halimbawa:',

        // boolean
        booleanYesLabel: "Oo",
        booleanNoLabel: "Hindi",

        //questionStep
        questionStepAudioQuestionLabel: "Tanong ng Boses",

        // errors
        invalidPhoneNumber: "{phone} ay hindi wastong numero ng telepono.",
        invalidEmail: "{email} ay hindi wastong email address.",
        questionIsRequired: "Kinakailangan ang tanong.",
        answerIsNotValid: "Hindi wasto ang sagot.",
        unfinishedProbingDialogError: "Pakibuo ang usapan.",
        cannotResumePartialResponse: "Hindi maipagpatuloy ang bahagi ng sagot.",
        failedToSubmitForm: "Nabigo sa pagpapasa ng form. Suriin ang iyong koneksyon sa internet at subukang muli.",

        //language picker
        searchLanguage: "Maghanap ng wika",
    }
}
